<template>
  <AuthLayout>
    <component :is="componentForm" />

    <div class="text-center mt-5">
      <p class="text-primary cursor-pointer font-light" @click="onToggleForm">
        {{
          isLogin
            ? "¿Olvidaste tu contraseña?"
            : "¿Tienes una cuenta? Inicia sesión"
        }}
      </p>
    </div>
  </AuthLayout>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from "vue";
import AuthLayout from "@/layouts/Auth";

const isLogin = ref(true);
const componentForm = computed(() =>
  isLogin.value
    ? defineAsyncComponent(() => import("./forms/Login.vue"))
    : defineAsyncComponent(() => import("./forms/RecoverPassword.vue"))
);

const onToggleForm = () => {
  isLogin.value = !isLogin.value;
};
</script>
