<template>
  <div class="w-full h-full lg:pt-16">
    <div class="text-center py-10">
      <h1 class="text-secondary text-xl font-medium my-auto">
        Hola, {{ getUserAuthenticated.name }}
      </h1>
      <h4 class="text-blue-900 font-light lg:pt-4">
        Bienvenido a la plataforma de gestión Bildin
      </h4>
    </div>
    <div>
      <div v-show="step === 1">
        <FirstStep @onContinue="onContinueStep" />
      </div>
      <div v-show="step === 2">
        <SecondStep @onContinue="onContinueStep" />
      </div>
      <div v-show="step === 3">
        <ThirdStep @onContinue="onContinueStep" />
      </div>
    </div>
    <div class="my-10">
      <div class="grid grid-cols-3 gap-4 w-24 m-auto text-center">
        <div>
          <p class="uppercase font-bold flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              class="rounded-full"
              :class="step >= 1 ? 'bg-primary' : 'bg-gray-400'"
            >
              <circle cx="5.5" cy="5.5" r="5.5" />
            </svg>
          </p>
        </div>
        <div>
          <p class="uppercase font-bold flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              class="rounded-full"
              :class="step >= 2 ? 'bg-primary' : 'bg-gray-400'"
            >
              <circle cx="5.5" cy="5.5" r="5.5" />
            </svg>
          </p>
        </div>
        <div>
          <p class="uppercase font-bold flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              class="rounded-full"
              :class="step >= 3 ? 'bg-primary' : 'bg-gray-400'"
            >
              <circle cx="5.5" cy="5.5" r="5.5" />
            </svg>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import FirstStep from "./steps/First";
import SecondStep from "./steps/Second";
import ThirdStep from "./steps/Third";

const store = useStore();

const step = ref(1);

const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);

const onContinueStep = (nextStep) => {
  step.value = nextStep;
};
</script>
