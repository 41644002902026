<template>
  <div class="flex justify-center text-center">
    <div class="p-6 px-8 rounded-md overflow-hidden shadow-lg lg:w-1/4">
      <Form
        @submit="onSave"
        :validation-schema="{
          name: 'required',
        }"
      >
        <div class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
          >
            <circle opacity="0.12" cx="40" cy="40" r="40" fill="#0057FF" />
            <path
              d="M57.0527 37.7656C58.4322 37.7656 59.008 35.9933 57.8875 35.1763L40.8384 22.7746C40.5949 22.5962 40.3009 22.5 39.9991 22.5C39.6973 22.5 39.4033 22.5962 39.1598 22.7746L22.1107 35.1763C20.9902 35.9888 21.5661 37.7656 22.95 37.7656H25.7134V54.4621H22.4991C22.3027 54.4621 22.142 54.6228 22.142 54.8192V57.1406C22.142 57.3371 22.3027 57.4978 22.4991 57.4978H57.4991C57.6955 57.4978 57.8563 57.3371 57.8563 57.1406V54.8192C57.8563 54.6228 57.6955 54.4621 57.4991 54.4621H54.2848V37.7656H57.0527ZM39.9991 25.9219L52.1018 34.7254H27.8964L39.9991 25.9219ZM28.9277 37.7656H34.1509V54.4621H28.9277V37.7656ZM37.3652 37.7656H42.5884V54.4621H37.3652V37.7656ZM51.0705 54.4621H45.8027V37.7656H51.0705V54.4621Z"
              fill="#0057FF"
            />
          </svg>
        </div>
        <div class="p-4">
          <h4 class="text-blue-900 font-light">Asigne un nombre de la obra</h4>
        </div>
        <div class="pb-4">
          <Field v-model="name" name="name" v-slot="{ field }">
            <input
              v-bind="field"
              type="text"
              class="
                relative
                py-3
                px-2
                w-full
                bg-white
                rounded
                border
                outline-none
                text-gray-700
                placeholder-gray-400
                focus:outline-none
              "
              placeholder="Ingrese nombre de la obra/proyecto"
            />
          </Field>
          <div>
            <ErrorMessage name="name" v-slot="{ message }">
              <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
            </ErrorMessage>
          </div>
        </div>
        <Alert
          v-if="responseMessage"
          :type="responseType"
          :text="responseMessage"
        ></Alert>
        <div class="my-2">
          <button
            class="rounded bg-primary mt-4 py-2 px-4 text-white"
            type="submit"
          >
            Continuar
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const store = useStore();

const emit = defineEmits(["onContinue"]);

const { value: name } = useField("name");
const responseType = ref(null);
const responseMessage = ref(null);

const onSave = async (values) => {
  try {
    await store.dispatch("createWork", { work: values, isCurrentWork: false });

    emit("onContinue", 2);
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al crear el proyecto/obra";
    console.error(error);
  }
};
</script>
