<template>
  <template v-if="getWorks">
    <div v-if="getShowStepper">
      <Stepper />
    </div>
    <div v-else class="py-8">
      <div class="divide-y divide-gray">
        <div class="text-center py-8">
          <h1 class="text-secondary text-xl font-medium my-auto">
            Hola, {{ getUserAuthenticated.name }}
          </h1>
          <h4 class="text-blue-900 font-light">
            Bienvenido a la plataforma de gestión Bildin
          </h4>
        </div>
        <div></div>
      </div>
      <div v-if="getWorks && getWorks.length">
        <h2
          class="text-center py-10 text-secondary text-xl font-medium my-auto"
        >
          Tus Obras
        </h2>
        <div
          class="
            grid grid-cols-1
            md:grid-cols-2
            xl:gap-6
            lg:gap-4
            gap-2
            lg:w-3/5
            xl:w-3/6
            md:w-2/3
            w-3/4
            m-auto
          "
        >
          <div
            v-for="work in getWorks"
            :key="work.key"
            class="
              flex
              sm:flex-row
              flex-col
              rounded-md
              overflow-hidden
              shadow-lg
              relative
            "
          >
            <div
              class="w-48 h-full bg-cover bg-center"
              :style="{
                backgroundImage: work.image
                  ? `url(${work.image}?${new Date().getTime()})`
                  : 'url(' + require('@/assets/edificios.jpeg') + ')',
              }"
            ></div>
            <!-- :style="image" -->
            <div class="lg:py-4 xl:py-8 md:py-4 py-4 px-6 xs:w-56 flex-1">
              <div class="text-blue-900 font-light pb-6 flex">
                <h4 class="normal-case">{{ work.name }}</h4>
                <router-link
                  v-if="getUserAuthenticated.type === roles.ADMIN"
                  :to="{ name: 'work-profile', params: { key: work.key } }"
                  class="
                    p-2
                    text-gray text-xl
                    font-bold
                    flex
                    absolute
                    top-2
                    right-2
                  "
                >
                  <svg viewBox="0 0 512 512" class="h-3 w-3">
                    <path
                      fill="currentColor"
                      d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                      class=""
                    ></path>
                  </svg>
                </router-link>
              </div>
              <!--
              <div class="pb-4">
                <div class="pb-6">
                  <h6 class="text-sm pb-1">Real 67 %</h6>
                  <div class="divide-solid divide-y-2 relative">
                    <div
                      class="
                        absolute
                        flex
                        align-center
                        items-center
                        align-middle
                        content-center
                        w-full
                      "
                      style="top: 50%"
                    >
                      <div
                        class="
                          w-full
                          bg-lightgray
                          rounded
                          items-center
                          align-middle align-center
                          flex-1
                        "
                      >
                        <div
                          class="w-0 bg-redDark py-1 rounded"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pb-6">
                  <h6 class="text-sm pb-1 text-primary">Contractual 17 %</h6>
                  <div class="divide-solid divide-y-2 relative">
                    <div
                      class="
                        absolute
                        flex
                        align-center
                        items-center
                        align-middle
                        content-center
                        w-full
                      "
                      style="top: 50%"
                    >
                      <div
                        class="
                          w-full
                          bg-lightgray
                          rounded
                          items-center
                          align-middle align-center
                          flex-1
                        "
                      >
                        <div
                          class="w-0 bg-primary py-1 rounded"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->
              <div>
                <button
                  class="
                    rounded-md
                    bg-primary
                    py-2
                    px-4
                    text-white
                    w-full
                    font-medium
                  "
                  @click="onLoginWork(work.key)"
                >
                  Ingresar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h2
          class="text-center py-10 text-secondary text-xl font-medium my-auto"
        >
          Aún no participas en ninguna obra.
        </h2>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Stepper from "./stepper/Stepper";

const app = getCurrentInstance();
const roles = app.appContext.config.globalProperties.$roles;

const store = useStore();
const router = useRouter();

const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getWorks = computed(() => store.getters.getWorks);
const getShowStepper = computed(() => store.getters.getShowStepper);

const init = async () => {
  await store.dispatch("resetWork");

  store.dispatch("toggleShowMenu", false);
};

init();

const onLoginWork = async (workKey) => {
  store.dispatch("setWork", { key: workKey });
  router.push({ name: "welcome" });
};
</script>
